import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const appContainer = document.getElementById('read-more-posts-app');

if (appContainer) {
    const root = ReactDOM.createRoot(root);

    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    );
}
